<script lang="ts" setup> 

    const props = defineProps({ 
        blok: Object 
    })

    const richtext = (value:any) => renderRichText(value)

    const { fetchFaqs, articles } = useFaqs()
    await fetchFaqs(props?.blok?.topic)

</script>

<template>
    <div v-editable="blok" class="grid grid-col-1" :class="[blok?.theme || 'dark']">
        <div class="flex flex-col gap-6 lg:gap-8">
      
            <div v-if="blok?.heading || blok?.subheading || blok?.richtext && richtext(blok?.richtext)" class="flex flex-col gap-4 justify-center items-center text-center max-w-[900px] mx-auto">
                <div v-if="blok?.heading || blok?.subheading" class="flex flex-col gap-2 justify-center items-center text-center">
                    <component
                        v-if="blok && blok?.heading" 
                        :is="`headings-${blok?.heading_tag || 'h3'}`" 
                        :value="blok?.heading"
                        :classes="blok?.heading_style || 'h2'" 
                    />
                    <component
                        v-if="blok && blok?.subheading" 
                        :is="`headings-${blok?.subheading_tag || 'h4'}`" 
                        :value="blok?.subheading"
                        :classes="blok?.subheading_style || 'h3'"
                    />
                </div>
                <div v-if="blok?.richtext && richtext(blok?.richtext)" v-html="richtext(blok?.richtext)" class="flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-white-50/75 dark:text-nevada-500"/>
            </div>

            <div v-if="articles && articles.length > 0" class="border-t-[2px] border-mystic-200">
                <details v-for="(article, index) in articles" :key="index" class="group">
                    <summary class="flex flex-row gap-4 cursor-pointer list-none items-center justify-between border-b-[2px] border-mystic-200 py-2">
                        <span class="font-hass-display text-h7_mobile md:text-h7_tablet lg:text-h7_desktop font-medium text-elephant-950">{{ article.content.title }}</span>
                        <div class="w-4 h-4 border-[2px] border-elephant-950 rounded-full flex flex-col justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" class="w-4 h-4 transition-all rotate-0 group-open:rotate-180">
                                <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" class="transition-all opacity-100  group-open:opacity-0"/>
                                <path d="M200-440v-80h560v80H200Z" class="transition-all opacity-0 group-open:opacity-100"/>
                            </svg>
                        </div>
                    </summary>
                    <div v-if="article && article.content && article.content.content" class="pt-4 pb-8 font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-nevada-500 border-b-[2px] border-mystic-200" v-html="article.content.content" />
                </details>
            </div>

            <div v-if="blok?.buttons && blok?.buttons.length > 0" class="flex flex-wrap gap-2 justify-center">
                <StoryblokComponent v-for="button in blok.buttons" :key="button._uid" :blok="button" />
            </div>

        </div>
   </div>
</template>