const state = reactive({

    articles: []

})

export function useFaqs() {

    async function fetchFaqs(topics:any) {
        
        const route = useRoute()
        const storyblokApi = useStoryblokApi()
        const config = useRuntimeConfig()
        const cv = config.public.cv
        const version = route.query?._storyblok ? 'draft' : 'draft'
        const filterQueryTopics = ref([])
        
        if(topics && topics.length > 0) {
            filterQueryTopics.value = topics.toString()
        }

        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'faq-s/articles/',
            version: version, 
            cv: Number(cv),
            resolve_relations: ['faqs_article.topic'],
            filter_query: {
                topic: {
                    in: filterQueryTopics.value
                }
            }
        }) 

        state.articles = data.stories

    }


    return {
        ...toRefs(state),
        fetchFaqs,
    }
}